import pick from "lodash/pick";

type Messages = typeof import("../../locales/en.json");

export type Translations = Partial<Messages>;

export const EnErrors = {
  unfortunately_error: "Unfortunately, something went wrong...",
  anyway_explore_website: "Anyway, let’s get you back to explore our website",
  go_to_homepage: "Go To Homepage",
  unfortunately_page: "Unfortunately, we couldn’t find this page",
};

/*
const EsErrors = {
  unfortunately_error: "Unfortunately, something went wrong...",
  anyway_explore_website: "Anyway, let’s get you back to explore our website",
  go_to_homepage: "Go To Homepage",
  unfortunately_page: "Unfortunately, we couldn’t find this page",
};
*/

export type TranslationModule = (text: string) => string;
export type TranslationModuleName = keyof Messages;

export function getTranslationModule(translations: Translations, module: TranslationModuleName): TranslationModule {
  const translationsModule: any = translations[module];
  if (!translationsModule) {
    throw new Error(`unknown translations module ${module}`);
  }

  return (text: string): string => {
    const result = translationsModule[text] as string;
    if (!result) {
      throw new Error(`no text '${text}' in module ${module}`);
    }

    return result;
  };
}

export function getLocaleText(translations: Translations, module: TranslationModuleName, text: string): string {
  return getTranslationModule(translations, module)(text);
}

export function pickTranslationsModules(translations: Translations, ...modules: TranslationModuleName[]): Translations {
  return pick(translations, ...modules);
}
